h1 {
  max-width: 68vw;
  margin-top: 10px;
  margin-bottom: 32px;
  font-family: Proximanova, sans-serif;
  color: #009473;
  font-size: 54px;
  line-height: 1.1;
  font-weight: 700;
}

h2 {
  max-width: 45vw;
  margin-top: 10px;
  margin-bottom: 32px;
  font-family: Proximanova, sans-serif;
  color: #009473;
  font-size: 48px;
  font-weight: 700;
}

h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  float: left;
  font-family: Proximanova, sans-serif;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Proximanova, sans-serif;
  color: #000;
  font-size: 28px;
  line-height: 1.5;
  font-weight: 600;
}

a {
  float: none;
  text-decoration: underline;
}

li {
  margin-bottom: 10px;
  font-family: Clinicapro, sans-serif;
  font-size: 16px;
  line-height: 1.75;
}

blockquote {
  min-width: 100%;
  margin-bottom: 10px;
  padding: 10px 0px;
  border-left: 5px none #e2e2e2;
  font-family: Proximanova, sans-serif;
  color: #149373;
  font-size: 38px;
  line-height: 1.2;
}

.wrapper {
  position: relative;
  z-index: 3;
  width: 97.1%;
  max-width: 1440px;
  margin-top: 0rem;
  padding: 85px 0rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: 1px solid #f2f8f7;
  border-left: 1px solid #f2f8f7;
}

.wrapper.paddings {
  width: 64%;
  padding: 85px 0px;
  border-right-style: none;
  border-left-style: none;
}

.wrapper.no-paddings {
  min-height: 100px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.wrapper.fotter {
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top-style: solid;
  border-top-color: #f8f8f8;
}

.wrapper.paddings-all {
  padding-right: 60px;
  padding-bottom: 0px;
  padding-left: 60px;
}

.wrapper.paddings-all.vert {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0px;
  padding-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.wrapper.single {
  padding-right: 25rem;
  padding-left: 25rem;
}

.wrapper.single.actions {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0%;
  padding-left: 0%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.wrapper.single.ac {
  padding-right: 25rem;
  padding-left: 25rem;
}

.wrapper.head-wrapper {
  padding-bottom: 30px;
}

.wrapper.no-bottom-padding {
  padding-bottom: 0px;
}

.link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin-top: 10px;
  padding-top: 5px;
  padding-right: 0px;
  padding-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: Proximanova, sans-serif;
  color: #000;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-decoration: none;
  text-transform: uppercase;
}

.link:hover {
  color: #009473;
}

.link.back-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 15px;
  margin-bottom: 0px;
  padding-right: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  font-size: 0.9rem;
  text-decoration: none;
}

.link.phone {
  margin-top: 0px;
  padding-top: 0px;
  padding-bottom: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 13px;
}

.link.drop-link {
  padding-right: 10px;
  padding-left: 10px;
}

.link.drop-link.w--current {
  color: #009473;
}

.card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: auto;
  min-height: 200px;
  margin-bottom: 0px;
  padding: 30px;
  float: left;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: 1px none #f3f3f3;
  border-bottom: 1px none #eee;
  border-left: 1px none #f3f3f3;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.card.logos-clients {
  width: 25%;
  height: 170px;
  min-height: auto;
  margin-bottom: 0px;
  padding: 20px 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  font-family: Clinicapro, sans-serif;
  color: #000;
  font-size: 16px;
  line-height: 1.75;
  text-decoration: none;
}

.image-12 {
  width: 100px;
  opacity: 0;
}

.fack-you-notik {
  position: absolute;
  left: auto;
  top: 0%;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #fff;
  box-shadow: 1px 1px 50px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.start-page-fack-you {
  position: absolute;
  left: auto;
  top: 0%;
  right: 25px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #fff;
  box-shadow: 1px 1px 50px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.kuki-fack-you {
  position: absolute;
  left: 30%;
  top: auto;
  right: 0%;
  bottom: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  height: auto;
  padding: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #fff;
  box-shadow: 1px 1px 50px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.old-fack-you {
  position: relative;
  left: auto;
  top: 0vh;
  right: auto;
  bottom: auto;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  padding: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #fff;
  box-shadow: 1px 1px 50px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.adress-fack-you {
  position: relative;
  left: auto;
  top: 0vh;
  right: auto;
  bottom: auto;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  padding: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #fff;
  box-shadow: 1px 1px 50px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.email-fack-you {
  position: relative;
  left: auto;
  top: 0vh;
  right: auto;
  bottom: auto;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  padding: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #fff;
  box-shadow: 1px 1px 50px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.ebuchi-chat {
  position: absolute;
  left: auto;
  top: auto;
  right: 25px;
  bottom: 25px;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 300px;
  height: 80%;
  max-height: 500px;
  padding: 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-style: solid;
  border-width: 1px;
  border-color: #4a59ff;
  border-radius: 25px;
  background-color: #fff;
  box-shadow: 1px 1px 50px 15px rgba(0, 0, 0, 0.1);
}

.you-find-fack-you {
  position: relative;
  left: auto;
  top: 0vh;
  right: auto;
  bottom: auto;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  padding: 50px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #fff;
  box-shadow: 1px 1px 50px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.final-fack-you {
  position: relative;
  left: auto;
  top: 0vh;
  right: auto;
  bottom: auto;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  height: auto;
  padding: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #fafeff;
  text-align: center;
}

.form-old {
  display: none;
  margin-top: 15px;
  margin-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  opacity: 0;
}

.body {
  margin-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  color: #000;
}

.section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #f2f8f7;
}

.section._100vh {
  min-height: 90vh;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.row-3x {
  width: 100%;
  min-height: 300px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0px;
  padding-left: 0px;
}

.col-3x {
  display: block;
  min-height: auto;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.col-3x.left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: auto;
  padding-right: 60px;
  padding-left: 60px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.col-3x.left._2 {
  padding-right: 30px;
  padding-left: 30px;
}

.col-3x.left.top-padding {
  padding-top: 60px;
}

.col-3x.left._4-img {
  padding-right: 0px;
  padding-left: 0px;
}

.col-3x.right._4-img-right {
  padding-left: 0px;
}

.div-block-31 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 9vw;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.small-h {
  max-width: 45vw;
  font-family: Proximanova, sans-serif;
  font-size: 10px;
  line-height: 2;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.small-h.brads {
  color: #009473;
}

.p-class {
  position: static;
  width: 100%;
  margin-bottom: 0px;
  padding-right: 20px;
  padding-bottom: 20px;
  float: left;
  font-family: Clinicapro, sans-serif;
  font-size: 16px;
  line-height: 1.75;
}

.p-class.fotter-links {
  padding-bottom: 0px;
  font-family: Proximanova, sans-serif;
  font-size: 13px;
  font-weight: 600;
  text-transform: none;
}

.p-class.exc {
  margin-top: 0px;
}

.p-class.ac {
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
}

.p-class.head {
  max-width: 50%;
}

.p-class._100 {
  max-width: 100%;
}

.before-txt-link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 20px;
  padding-left: 20px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.before-txt-link.revers {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.image-15 {
  position: absolute;
  left: auto;
  top: -5px;
  right: 6px;
  bottom: 0%;
  width: auto;
  height: 30px;
  max-width: none;
}

.fon-arrow {
  position: static;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  padding-right: 5px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(20, 147, 115, 0.15);
}

.link-menu {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  margin-right: 20px;
  margin-left: 20px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: Proximanova, sans-serif;
  color: #000;
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
  letter-spacing: 0.2em;
  text-decoration: none;
  text-transform: uppercase;
}

.link-menu:hover {
  color: #009473;
}

.left-link-line {
  position: absolute;
  left: 0%;
  top: auto;
  right: auto;
  bottom: 0%;
  width: 50%;
  height: 1px;
  background-color: #009473;
}

.right-link-line {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 50%;
  height: 1px;
  background-color: #009473;
}

.txt-slider {
  z-index: 3;
  width: 200px;
  height: 50px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: transparent;
}

.brand-3 {
  width: 200px;
  height: 50px;
}

.top-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-2 {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 148, 115, 0.15);
  background-color: #fff;
}

.nav-menu-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-toggle {
  margin-right: auto;
  margin-left: 0px;
  padding: 0px 20px 10px 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-family: Proximanova, sans-serif;
  color: #000;
  font-size: 10px;
  line-height: 15px;
  font-weight: 600;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.dropdown-toggle:hover {
  color: #009473;
}

.flex-table {
  display: flex;
  justify-content: space-around;
  border: 1px solid #008049
}

.table-col {
  display: block;
  width: 100%;
  border-right: 1px solid #009473;
}

.table-col-item {
  display: flex;
  border-bottom: 1px solid #008049;
  font-family: Proximanova, sans-serif;
  font-size: 13px;
  width: 100%;
  min-height: 80px;
  padding: 5px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  line-height: 1.75;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.table-col-item.tops {
  background-color: #f6fcfc;
  font-size: 16px;
  color: #000;
  letter-spacing: 0em;
  text-transform: none;
  font-weight: bold;
  font-family: Clinicapro, sans-serif;
}

.ico .w-icon-dropdown-toggle {
  height: 15px;
  margin-top: 0px;
  margin-right: 0px;
  background-image: url("./img/5d2447d460878d7ea056eb6f_down-arrow.svg");
  background-position: 40% 50%;
  background-size: 7px;
  background-repeat: no-repeat;
  color: transparent;
}

.d-down {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-weight: 400;
}

.dropdown-list {
  top: 100%;
  overflow: hidden;
  height: 0px;
  background-color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.dropdown-list.w--open {
  left: -20px;
  height: auto;
  margin-top: 0px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border-right: 1px solid rgba(0, 148, 115, 0.15);
  border-bottom: 1px solid rgba(0, 148, 115, 0.15);
  border-left: 1px solid rgba(0, 148, 115, 0.15);
  background-color: #fff;
}

.card-ico {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  margin-bottom: 0px;
  float: left;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: transparent;
}

.ico-in-card {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  background-image: url("./img/5d2453ba1a002338317a43a9_carpenter.svg");
  background-position: 50% 50%;
  background-size: 60%;
  background-repeat: no-repeat;
}

.ico-in-card.seminar {
  background-image: url("./img/5d2de02b2be6babc16e2fdc5_boss.svg");
}

.ico-in-card.training {
  background-image: url("./img/5d2de068b1d78930a24f7d5b_training.svg");
}

.slider {
  z-index: 3;
  width: 97.1%;
  height: 100vh;
  max-width: 1440px;
  min-height: 100vh;
  margin-top: 0px;
  padding-top: 50px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
  background-color: transparent;
}

.div-in-slide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 120px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.right-arrow {
  left: 50%;
  right: auto;
  bottom: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100px;
  height: 20px;
  margin-top: auto;
  margin-bottom: 0px;
  margin-left: 20px;
  padding-right: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Proximanova, sans-serif;
  color: #000;
  font-size: 10px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.right-arrow.otzivas {
  left: auto;
  top: 50%;
  right: 60px;
  bottom: auto;
  margin-top: 60px;
  margin-bottom: auto;
}

.left-arrow {
  left: auto;
  right: 50%;
  bottom: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100px;
  height: 20px;
  margin: auto 20px 0px 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Proximanova, sans-serif;
  color: #000;
  font-size: 10px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.left-arrow.otzivas {
  right: 60px;
  bottom: 50%;
  margin-right: 0px;
  margin-bottom: 60px;
}

.col-in-slider {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding-bottom: 120px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.col-in-slider._4-img {
  width: 50%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.col-in-slider._1 {
  width: 50%;
  padding-right: 60px;
  padding-left: 60px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.col-in-slider.primeras {
  width: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.h2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 60vw;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-family: Proximanova, sans-serif;
  color: #000;
  font-size: 34px;
  line-height: 1.2;
  font-weight: 700;
}

.h2.slider-h {
  color: #009473;
}

.h2.ac {
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.div-block-32 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.fon-green {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 2;
  width: 140px;
  background-color: #008049;
}

.fon-green.right {
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  background-color: #008049;
}

.fon-greeer {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 1;
  width: 50%;
  background-color: #edf5f5;
}

.fon-greeer._2 {
  width: 100%;
}

.slide-nav {
  bottom: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 20px;
  padding-top: 0px;
  padding-left: 60px;
  font-family: Proximanova, sans-serif;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
}

.slide-nav.none {
  display: none;
}

.wrapper-in-head {
  position: relative;
  z-index: 3;
  width: 97.1%;
  max-width: 1440px;
  margin-top: 0rem;
  padding-top: 0px;
  padding-right: 0rem;
  padding-left: 0rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.slide-nav-2 {
  display: none;
}

.right-arrow-2 {
  display: none;
}

.left-arrow-2 {
  display: none;
}

.slide {
  height: 50px;
  max-height: 50px;
}

.div-block-34 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding-top: 2px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: Proximanova, sans-serif;
  color: #009473;
  font-size: 16px;
  text-transform: uppercase;
}

.vertical-line {
  position: absolute;
  left: 75%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: -1;
  width: 1px;
  border-right: 1px solid #f2f8f7;
}

.vertical-line._50 {
  left: auto;
  margin-right: 1px;
  border-right-style: none;
  background-color: #f2f8f7;
}

.in-wrapper {
  margin-left: auto;
  padding-right: 60px;
  padding-left: 60px;
}

.in-wrapper.entres {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 85px;
  padding-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #f8f8f8;
}

.logos-clients {
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.img-client {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100px;
  height: 70px;
  max-height: 70px;
  margin-right: 20px;
  margin-bottom: 10px;
  padding: 15px 15px 15px 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  opacity: 1;
  -webkit-filter: contrast(100%) grayscale(100%);
  filter: contrast(100%) grayscale(100%);
}

.vertical-line-25 {
  position: absolute;
  left: 25%;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: -1;
  width: 1px;
  border-right: 1px solid #f2f8f7;
}

.left-div {
  position: relative;
  z-index: 3;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  margin-left: 50%;
  padding: 85px 60px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.slider-otzivi {
  height: 500px;
  background-color: transparent;
}

.div-in-otzivas {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #f2f8f7;
}

.col-otzivas {
  width: 25%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.col-otzivas._50-proc {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  /* padding: 60px 25px 60px 100px; */
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.col-otzivas._50-proc.paper-otzvs {
  width: 55%;
}

.col-otzivas._50-proc.left {
  padding-right: 60px;
  padding-left: 60px;
}

.img-people-otzivas {
  height: 500px;
  background-image: url("./img/5d248b77a5dbec77d7577ba7_K6uCtOXJwBk.jpg");
  background-position: 50% 50%;
  background-size: cover;
}

.img-people-otzivas.otzivas {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-image: none;
}

.img-people-otzivas.in-home {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  background-image: none;
}

.itatas {
  color: #009473;
  font-size: 24px;
  line-height: 1.5;
}

.slider-primers {
  z-index: 3;
  width: 100%;
  height: 80vh;
  max-width: none;
  border-right: 1px none #eee;
  border-left: 1px none #eee;
  background-color: transparent;
}

.div-in-slide-primeras {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.news {
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.card-news {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  height: auto;
  min-height: 450px;
  margin-bottom: 0px;
  padding: 60px 30px 60px 60px;
  float: left;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-style: solid none none;
  border-width: 1px;
  border-color: #f2f8f7 #f3f3f3 #eee;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.card-news:hover {
  background-color: #fff;
}

.fotter {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 1px none rgba(0, 148, 115, 0.15);
}

.fotter-rows {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.fotter-clos {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 50px;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.text-link {
  color: #009473;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.2em;
  text-decoration: none;
  text-transform: uppercase;
}

.arrow-line {
  width: 7px;
  max-width: 7px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.line-arrow {
  position: absolute;
  left: auto;
  top: auto;
  right: 8px;
  bottom: 9.5px;
  width: 35px;
  height: 1px;
  background-color: #009473;
}

.slide-nav-3 {
  display: none;
}

.paddinger {
  height: 100px;
}

.top-for-brads {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.top-for-brads.lefts {
  width: 100%;
  padding-right: 60px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.brads {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.brads-arrow {
  width: 7px;
  max-width: 7px;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.img-in-news {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: none;
  width: 50%;
  height: 100%;
  /* background-image: url("./img/5d248b77a5dbec77d7577ba7_K6uCtOXJwBk.jpg"); */
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.img-in-news.newser {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: none;
}

.h1-single {
  max-width: none;
  font-size: 48px;
}

.div-block-13 {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  height: 1px;
  background-color: #009473;
}

.div-in-tab {
  position: relative;
  padding-top: 0vw;
  padding-bottom: 0vw;
}

.col-co {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 80px;
  padding: 5px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top: 1px solid #009473;
  border-left: 1px solid #009473;
  background-color: #fff;
  font-family: Proximanova, sans-serif;
  font-size: 10px;
  line-height: 1.75;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
}

.col-co.tops {
  padding-right: 30px;
  padding-left: 30px;
  background-color: #f6fcfc;
  font-family: Clinicapro, sans-serif;
  color: #000;
  font-size: 13px;
  letter-spacing: 0em;
  text-transform: none;
}

.row-col {
  position: relative;
  left: -1px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.mama-obrazci {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-filter: hue-rotate(322deg) brightness(110%) grayscale(80%);
  filter: hue-rotate(322deg) brightness(110%) grayscale(80%);
}

.image-16 {
  max-height: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.card-obuchenie {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  height: auto;
  min-height: 560px;
  margin-bottom: 0px;
  padding: 60px 30px;
  float: left;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: 1px none #f3f3f3;
  border-bottom: 1px none #eee;
  border-left: 1px none #f3f3f3;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.html-embed {
  width: 100%;
  -webkit-filter: hue-rotate(322deg) contrast(80%);
  filter: hue-rotate(322deg) contrast(80%);
}

._4-imgis {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  min-height: 300px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

._4-imgis.pops {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.line-gens {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 5px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.green-line {
  width: 100%;
  height: 1px;
  margin-bottom: 1px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #009473;
}

.card-obuchenie-bigger {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: auto;
  min-height: auto;
  margin-bottom: 0px;
  padding: 60px;
  float: left;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-right: 1px none #f3f3f3;
  border-bottom: 1px none #eee;
  border-left: 1px none #f3f3f3;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.h1-how {
  font-size: 38px;
}

.img-otziv {
  height: 400px;
  border: 1px solid #f2f8f7;
}

.img-aciya {
  height: auto;
  border: 1px solid #f2f8f7;
}

.no-bottom-link {
  color: #000;
  text-decoration: none;
}

.no-bottom-link:hover {
  color: #009473;
}

.small-text {
  color: #adabab;
  font-size: 13px;
  line-height: 14px;
}

.span-ogo {
  font-family: Proximanova, sans-serif;
  color: #009473;
  font-size: 24px;
  line-height: 0;
  font-weight: 700;
}

.body-2 {
  overflow: hidden;
  height: 100vh;
  background-color: rgba(237, 245, 245, 0);
}

.div-block-35 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  /* background-image: url("./img/5d551948ed71c943f3abb58a_Beach_Condo_3D_2.png"); */
  background-position: 50% 0%;
  background-size: auto 130%;
  background-repeat: no-repeat;
  opacity: 1;
  -webkit-filter: contrast(20%) grayscale(100%) brightness(173%);
  filter: contrast(20%) grayscale(100%) brightness(173%);
}

.div-block-36 {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: rgba(0, 148, 115, 0.15);
}

.div-block-37 {
  width: 1px;
  height: 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: rgba(0, 148, 115, 0.34);
}

html.w-mod-js *[data-ix="start-inner"] {
  display: none;
  opacity: 0;
}

html.w-mod-js *[data-ix="dop-navy"] {
  opacity: 0;
  -webkit-transform: translate(0px, 50px);
  -ms-transform: translate(0px, 50px);
  transform: translate(0px, 50px);
}

@media (max-width: 991px) {
  h1 {
    font-size: 28px;
  }

  h2 {
    margin-bottom: 15px;
    font-size: 26px;
  }

  li {
    font-size: 14px;
  }

  blockquote {
    font-size: 24px;
  }

  .wrapper {
    padding-top: 40px;
  }

  .wrapper.paddings {
    text-align: left;
  }

  .wrapper.fotter {
    padding-bottom: 0px;
  }

  .wrapper.paddings-all {
    padding-right: 20px;
    padding-left: 20px;
  }

  .wrapper.single {
    padding-right: 20px;
    padding-left: 20px;
  }

  .wrapper.single.ac {
    padding-right: 20px;
    padding-left: 20px;
  }

  .link.phone {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
  }

  .link.drop-link {
    margin-top: 0px;
    padding-right: 20px;
    padding-left: 20px;
    border-bottom: 1px solid #fff;
    font-size: 12px;
    font-weight: 400;
  }

  .card {
    width: 100%;
  }

  .kuki-fack-you {
    left: 20%;
    width: 60%;
  }

  .section {
    min-width: 100%;
  }

  .col-3x.left {
    padding-right: 20px;
    padding-left: 20px;
  }

  .col-3x.right._4-img-right {
    padding-right: 20px;
    padding-left: 20px;
  }

  .small-h.brads {
    font-size: 10px;
  }

  .p-class {
    font-size: 14px;
  }

  .txt-slider {
    display: none;
  }

  .top-menu {
    padding-bottom: 10px;
  }

  .nav-menu-block {
    padding-top: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    background-color: #fff;
  }

  .dropdown-toggle {
    padding-top: 10px;
    padding-left: 20px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }

  .ico .w-icon-dropdown-toggle {
    margin-top: auto;
    margin-right: 20px;
    margin-bottom: auto;
  }

  .d-down {
    border-top: 1px none #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
  }

  .dropdown-list.w--open {
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    background-color: #f3f3f3;
  }

  .slider {
    height: auto;
  }

  .div-in-slide {
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .right-arrow {
    left: auto;
    right: 0px;
  }

  .right-arrow.otzivas {
    left: auto;
    top: auto;
    right: 60px;
    bottom: 60px;
  }

  .left-arrow {
    right: 140px;
    margin-right: 0px;
  }

  .left-arrow.otzivas {
    right: 190px;
    bottom: 60px;
    margin-bottom: 0px;
  }

  .col-in-slider._4-img {
    height: auto;
    padding-bottom: 0px;
  }

  .col-in-slider._1 {
    width: 50%;
    height: auto;
    padding-right: 20px;
    padding-bottom: 60px;
    padding-left: 20px;
  }

  .h2 {
    font-size: 24px;
  }

  .h2.slider-h {
    font-size: 24px;
  }

  .fon-green {
    display: none;
  }

  .fon-green.right {
    display: none;
  }

  .slide-nav {
    padding-left: 20px;
  }

  .in-wrapper {
    padding-right: 20px;
    padding-left: 20px;
  }

  .col-otzivas._50-proc {
    width: 55%;
  }

  .col-otzivas._50-proc.left {
    padding: 20px;
  }

  .itatas {
    font-size: 20px;
  }

  .news.page-news {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .card-news {
    width: 50%;
    min-height: 380px;
    padding: 40px 20px;
  }

  .fotter-clos {
    padding-right: 20px;
    padding-bottom: 30px;
    padding-left: 20px;
  }

  .paddinger {
    height: 80px;
  }

  /* .top-for-brads.lefts {
    display: none;
    padding-right: 0px;
  } */

  .brads {
    /* display: none;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto; */
    visibility: hidden;
  }

  .h1-single {
    font-size: 28px;
  }

  .div-in-tab {
    padding-top: 0vw;
    padding-bottom: 0vw;
    border-bottom: 1px solid #ebebeb;
  }

  .div-in-tab.tables {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-left: 1px solid #009473;
  }

  .col-co {
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    letter-spacing: 0em;
    text-transform: none;
  }

  .col-co.tops {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 12px;
  }

  .row-col {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .card-obuchenie {
    width: 50%;
    min-height: auto;
  }

  .glitch__img.otziv {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .h1-how {
    font-size: 24px;
  }

  .menu-button-3 {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    z-index: 5;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 60px;
    height: 60px;
    margin-top: 10px;
    padding: 0px 20px 0px 0vw;
    float: left;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    line-height: 50px;
  }

  .menu-button-3.w--open {
    background-color: transparent;
    color: #000;
  }

  .div-block-33 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .line-burger-4 {
    width: 25px;
    height: 2px;
    margin-bottom: 5px;
    background-color: #000;
  }

  .line-burger-2 {
    width: 25px;
    height: 2px;
    margin-bottom: 5px;
    background-color: #000;
  }

  .line-burger-2._2 {
    width: 14px;
    margin-top: 0px;
    margin-bottom: 5px;
  }

  .line-burger-3 {
    width: 25px;
    height: 2px;
    margin-top: 0px;
    margin-bottom: 5px;
    background-color: #000;
  }
}

@media (max-width: 767px) {
  .card.logos-clients {
    width: 50%;
    padding-left: 20px;
  }

  .kuki-fack-you {
    left: 10%;
    width: 80%;
  }

  .col-3x.left {
    padding-top: 6vw;
  }

  .col-3x.left._4-img {
    padding-top: 0vw;
  }

  .small-h {
    line-height: 120%;
    text-transform: lowercase;
  }

  .slider-otzivi {
    height: auto;
  }

  .col-otzivas {
    width: 50%;
  }

  .slider-primers {
    height: auto;
  }

  .news {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .mama-obrazci {
    height: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .image-16 {
    height: auto;
  }

  ._4-imgis {
    margin-top: 40px;
    padding-bottom: 6vw;
  }

  .top-for-brads.lefts {
    display: none;
    padding-right: 0px;
  }

  .brads {
    display: none;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }
}

@media (max-width: 479px) {
  h1 {
    max-width: 100%;
  }

  h2 {
    max-width: 100vw;
  }

  blockquote {
    font-size: 20px;
    font-weight: 400;
  }

  .ico .w-icon-dropdown-toggle {
    margin: auto;
    margin-right: 20px;
  }

  .wrapper {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .wrapper.paddings {
    width: 80%;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .wrapper.paddings-all {
    padding-top: 20px;
  }

  .wrapper.single.actions {
    padding-right: 20px;
    padding-left: 20px;
  }

  .link.back-link {
    margin-bottom: 0px;
  }

  .card.logos-clients {
    width: 50%;
    height: auto;
    padding-right: 20px;
    padding-left: 20px;
  }

  .fack-you-notik {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
  }

  .start-page-fack-you {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    width: 100%;
  }

  .old-fack-you {
    width: 90%;
    padding: 20px;
  }

  .adress-fack-you {
    width: 90%;
    padding: 20px;
  }

  .email-fack-you {
    width: 90%;
    padding: 20px;
  }

  .ebuchi-chat {
    right: 5%;
    bottom: 20px;
    display: none;
    width: 90%;
  }

  .you-find-fack-you {
    width: 90%;
  }

  .final-fack-you {
    width: 90%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .form-old {
    display: none;
    opacity: 0;
  }

  .section {
    height: auto;
    min-height: auto;
    padding-top: 0vw;
    padding-bottom: 0vw;
  }

  .row-3x {
    min-height: auto;
  }

  .col-3x.left {
    padding-bottom: 20px;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .col-3x.left._4-img {
    padding-right: 20px;
    padding-left: 20px;
  }

  .small-h {
    max-width: none;
    letter-spacing: 0.1em;
  }

  .p-class {
    padding-right: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 14px;
  }

  .p-class.fotter-links {
    color: #9b9b9b;
  }

  .p-class.head {
    max-width: 100%;
  }

  .brand-3 {
    height: 30px;
    padding-bottom: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-size: auto 60%;
  }

  .top-menu {
    height: 60px;
    padding-top: 0px;
    padding-bottom: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .navbar-2 {
    height: 60px;
  }

  .slider {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-height: none;
    min-height: auto;
    padding-top: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .div-in-slide {
    padding-top: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .right-arrow {
    position: absolute;
    left: 50%;
  }

  .right-arrow.otzivas {
    right: 20px;
  }

  .left-arrow {
    position: absolute;
    right: 50%;
    margin-right: 20px;
  }

  .col-in-slider._4-img {
    overflow: hidden;
    width: 100%;
    height: 250px;
    margin-bottom: 20px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .col-in-slider._1 {
    width: 100%;
  }

  .h2 {
    max-width: 100%;
  }

  .h2.slider-h {
    max-width: 100%;
    margin-bottom: 5px;
    font-size: 20px;
  }

  .fon-greeer {
    display: none;
  }

  .slide-nav {
    display: none;
  }

  .wrapper-in-head {
    height: 100%;
  }

  .in-wrapper {
    padding-top: 40px;
  }

  .in-wrapper.entres {
    padding-bottom: 85px;
  }

  .logos-clients {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .left-div {
    width: 100%;
    margin-left: 0%;
    padding-top: 40px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .slider-otzivi {
    height: 100vh;
  }

  .div-in-otzivas {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .col-otzivas {
    width: 100%;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .col-otzivas._50-proc {
    width: 100%;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .img-people-otzivas {
    height: 50vh;
  }

  .img-people-otzivas.otzivas {
    height: auto;
  }

  .itatas {
    font-size: 16px;
  }

  .news.page-news {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .card-news {
    width: 100%;
    min-height: auto;
    min-width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .fotter {
    background-color: #f8f8f8;
  }

  .text-link {
    color: #fff;
  }

  .paddinger {
    height: 60px;
  }

  .card-obuchenie {
    width: 100%;
    min-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  ._4-imgis {
    margin-top: 0px;
  }

  ._4-imgis.pops {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  .glitch__img.otziv {
    margin-right: auto;
    margin-left: auto;
  }

  .card-obuchenie-bigger {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }

  .img-aciya {
    width: 100%;
    height: auto;
  }

  .menu-button-3 {
    height: 40px;
    margin-top: 10px;
    padding-left: 17.5px;
  }

  .div-block-33 {
    padding-top: 5px;
  }

  .line-burger-4 {
    background-color: #009473;
  }

  .line-burger-2._2 {
    width: 25px;
    background-color: #009473;
  }

  .line-burger-3 {
    background-color: #009473;
  }

  .mask {
    height: auto;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .slide-2 {
    height: auto;
  }
}